.rich-text-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.h1,
.h2,
.h3 {
  text-align: left;
}

.h3 {
  font-size: var(--font-sm);
}

.link {
  font-weight: var(--font-weight-semiBold);
  font-size: var(--font-sm);
  color: var(--theme-blue);
}

.sub-heading {
  color: var(--text-darker-grey);
  font-size: var(--font-sm);
}

.title {
  font-size: var(--font-4xl);
  font-weight: var(--font-weight-midBold);
}

.cta {
  padding: 10px 18px;
  text-align: center;
  width: fit-content;
  border-radius: var(--br-xxs);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-sm);
  border: none;
  cursor: pointer;
  color: var(--black);
  transition: all 0.5s ease;
  background-color: var(--yellow);

  display: flex;
  align-items: center;
  gap: 6px;
}

.cta:hover {
  box-shadow: var(--section-card-box-shadow);
}

.paragraph {
  width: 100%;
  color: var(--black);
  font-size: var(--font-sm);
  line-height: 24px;
}

.ol,
.ul {
  color: var(--black);
  font-size: var(--font-sm);
  list-style-position: inside;
}

.ol li,
.ul li {
  font-size: var(--font-sm);
  list-style-type: disc;
  list-style-position: inside;
  line-height: 24px;
}

@media only screen and (min-width: calc(var(--breakpoints-sm))) {
  .rich-text-root {
    border-radius: var(--border-radius-sm);
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
